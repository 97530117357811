import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Git Remove All Local Branches",
  "author": "tzookb",
  "type": "post",
  "date": "2019-11-22T13:50:08.000Z",
  "url": "/git-remove-all-local-branches",
  "categories": ["git"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After a few sprints you git repo may explode with a bunch of different branches.
In that case you can easilly remove all of them (except master) with this simple command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git branch | grep -v "master" | xargs git branch -D 
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      